///
/// @file layout/_footer.scss
///
/// \brief Styles for site-wide page footers
///
///

$footer-email-signup-bg: #ffe2e6;
$footer-border-image-height: 60px;

.site-footer {
  background: $color-pink-2;
  background-size: cover;
  a {
    cursor: pointer;
    text-decoration: none;
  }
  .footer-category__title,
  .social-media-links__header {
    font-weight: bold;
    margin-bottom: 5px;
    letter-spacing: 3px;
    text-transform: uppercase;
    white-space: normal;
  }
  .icon {
    font-size: 50px;
  }
  &__signup {
    .site-email-signup {
      padding: 40px 5% 35px;
      background: $footer-email-signup-bg;
      max-width: 100%;
      @include breakpoint($large-up) {
        width: 31vw;
        max-width: 440px;
      }
      &:first-child {
        padding-bottom: 20px;
      }
    }
    .site-email-signup__field {
      border: none;
      margin-bottom: 0;
      text-align: center;
      width: 100%;
      input {
        padding: 16px 10px 0;
      }
    }
    .site-email-signup__submit {
      max-width: 100%;
      width: 100%;
      margin: auto;
    }
    input.adpl--processed + label:before,
    input.adpl--processed + .adpl-label:before {
      transform-origin: center center;
    }
  }
  &__bottom {
    background: $color-pink-2;
    display: block;
    width: 100%;
    @include breakpoint($landscape-up) {
      display: flex;
    }
    .site-footer__copyright {
      padding: 10px;
      @include breakpoint($landscape-up) {
        flex: 1;
        order: 1;
      }
      &__message {
        text-align: center;
        @include breakpoint($landscape-up) {
          float: right;
          text-align: right;
          width: 50%;
        }
      }
    }
    .site-footer__accessibility {
      padding: 0 0 15px;
      text-align: center;
      @include breakpoint($landscape-up) {
        padding: 10px 25px 10px 10px;
        order: 2;
      }
    }
  }
  .site-footer__signup + .footer-category {
    position: relative;
    @include breakpoint($small-down) {
      padding-top: 50px;
    }
    &:before {
      @include breakpoint($small-down) {
        @include borderGradient;
        content: ' ';
        top: 0;
      }
    }
  }
  .footer-category {
    &.site-footer__regional {
      &[data-language-count='0'],
      &[data-language-count='1'] {
        select {
          background-image: none;
        }
        a.selectBox {
          pointer-events: none;
          background-color: transparent;
          .selectBox-label {
            padding-right: 0;
          }
          .selectBox-arrow {
            display: none;
          }
        }
      }
      .site-footer__country {
        font-weight: 700;
        margin-bottom: 5px;
        letter-spacing: 3px;
        text-transform: uppercase;
        white-space: nowrap;
      }
      .site-footer__language {
        border: 0;
        font-size: 14px;
        .selectBox-label {
          font-weight: bold;
          padding: 0 40px 0 0;
          text-transform: uppercase;
        }
        .selectBox-arrow {
          background-repeat: no-repeat;
          background-position: right 10px top 50%;
          background-size: 10px;
          background-image: url('#{$base-theme-path}svg-icons/src/arrow--down.svg');
          &:after {
            display: none;
          }
        }
      }
      select.site-footer__language {
        padding: 0 40px 0 0;
        font-weight: bold;
        width: auto;
        background-color: transparent;
      }
    }
  }
  .site-email-signup__title {
    font-size: 29px;
    letter-spacing: 2px;
    line-height: 1;
    margin-bottom: 2px;
  }
  .site-email-signup__subtitle {
    font-size: 16px;
    font-style: normal;
    margin-bottom: 10px;
    @include breakpoint($medium-up) {
      font-size: 20px;
      letter-spacing: 1px;
      margin-bottom: 10px;
      padding-top: 15px;
    }
  }
  .social-media-links {
    a {
      display: inline-block;
      margin: 0 1px;
    }
    svg {
      display: block;
      fill: $color-darkest-gold;
      height: auto;
      width: 36px;
      @include breakpoint($medium-up) {
        width: 48px;
      }
    }
  }
  .footer-category__link {
    margin-bottom: 2px;
  }
  @include breakpoint($medium-down) {
    &__signup {
      border-bottom: 10px solid $color-white;
    }
    .site-email-signup__submit {
      margin-bottom: 0;
      margin-top: 10px;
      width: 100%;
      display: block;
      height: 40px;
      line-height: 40px;
    }
    .footer-category,
    .social-media-links {
      padding: 1% 16%;
      margin: 10px auto;
      @include breakpoint($large-up) {
        padding: 72px 16%;
      }
    }
    .social-media-links {
      padding: 10px;
      text-align: center;
      @include breakpoint($large-up) {
        padding-bottom: 50px;
      }
    }
    .social-media-links__header {
      display: none;
    }
    .footer-category__title {
      border-bottom: 1px solid $color-gold;
    }
  }
  @include breakpoint($medium-portrait-only) {
    .footer-category,
    .social-media-links {
      padding: 1% 22%;
      margin: 10px auto;
    }
  }
  &__menu {
    @include breakpoint($large-up) {
      padding: 0 3%;
    }
    @include breakpoint($xlarge-up) {
      padding: 0 10%;
    }
  }
  &__links-wrapper {
    position: relative;
    display: inherit;
  }
  &__trustmark {
    position: absolute;
    bottom: 0;
    right: 16%;
    img {
      height: 100px;
      display: block;
    }
  }
  @include breakpoint($large-up) {
    padding: 20px 25px 0;
    &__main {
      padding: 0 0 65px;
      max-width: $max-width-large;
      margin: auto;
      background-size: 100% 100%;
      position: relative;
      background-position: 0 $footer-border-image-height; // a magic number
      background-repeat: no-repeat;
      &:before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: $footer-border-image-height;
        background-size: 100% auto;
        background-repeat: no-repeat;
      }
    }
    &.lazyloaded &__main {
      background-image: url(#{$base-theme-path}/img/footer-border-side.png);
      &:before {
        background-image: url(#{$base-theme-path}/img/footer-border-top.png);
      }
    }
    &__links {
      display: flex;
      height: 330px;
      width: 100%;
      align-items: flex-start;
    }
    &__signup {
      order: 2;
      transform: translateY(-100px);
      flex: 25%;
      @include breakpoint($xlarge-up) {
        margin-left: 50px;
      }
    }
    .footer-category {
      flex: 0 1 auto;
      margin-top: 85px;
      margin-#{$rdirection}:24px;

      .selectBox {
        height: auto;
        line-height: inherit;
      }
    }
    .footer-category__links {
      margin-top: 10px;
    }
    .footer-category__link {
      font-size: 14px;
    }
    .site-email-signup__fields {
      margin: 0;
    }
    .social-media-links__header {
      margin: 15px auto;
    }
  }
  &__language-selectBox-dropdown-menu.selectBox-dropdown-menu {
    li {
      a {
        font-weight: normal;
        color: $color-black;
      }
      &.selectBox-selected {
        a {
          background-image: url('#{$base-theme-path}img/select-box/verification-mark.png');
          background-position: 90% 50%;
          background-repeat: no-repeat;
          border-top: 1px solid $color-lighter-gray;
          border-bottom: 1px solid $color-lighter-gray;
        }
      }
    }
  }
  .checkout-viewcart &,
  .checkout-index &,
  .active-panel-registration & {
    display: none;
  }
}

.site-footer__promo {
  @include gradientBorderBottom;
  background-color: $color-pink-3;
  padding-bottom: 22px;
  position: relative;
  &:after {
    bottom: 11px;
    display: block;
  }
  @include breakpoint($large-up) {
    background-color: transparent;
    background-image: linear-gradient(
      to bottom,
      transparent,
      transparent 20px,
      $color-pink-3 20px,
      $color-pink-3 70px,
      $color-pink-3 70px
    );
    height: 90px;
    padding-bottom: 0;
    margin-top: -20px;
    display: flex;
    align-items: center;
    &:after {
      display: none;
    }
  }
}

.site-footer__banner {
  margin-top: -20px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  width: 100%;
  @include breakpoint($large-up) {
    padding-top: 20px;
    position: relative;
    z-index: 3;
    max-width: 100%;
    margin: -25px auto 0;
    &:after {
      display: none;
    }
  }
  img {
    max-width: 200px;
  }
}

.site-footer__copyright {
  float: right;
  background: $color-pink-2;
  width: 100%;
  padding: 10px;
  &__message {
    text-align: center;
    @include breakpoint($landscape-up) {
      width: 50%;
      float: right;
      padding-right: 3%;
      text-align: right;
    }
  }
}
