///
/// @file layout/_page.scss
///
/// \brief
///
///

// max-width centered container
@mixin content-container {
  margin-left: auto;
  margin-right: auto;
  max-width: $max-width-large;
  &--large {
    max-width: $max-width-large;
  }
  &--medium {
    max-width: $max-width;
  }
  &--small {
    max-width: $max-width-small-formatters;
  }
  &--full {
    max-width: 100%;
  }
}

.content-container {
  @include content-container;
}

.site-content {
  @include breakpoint($large-up) {
    padding-bottom: 120px; // magic number for footer email signup overlap
  }
}
