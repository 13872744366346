///
/// @file layout/_header.scss
///
/// \brief Styles for site-wide page headers (brand logo, etc)
//////

.transparent-header {
  &.front,
  &.page-products,
  &.node-type-page,
  &.page-node {
    .site-content {
      padding-top: 40px;
    }
    @include breakpoint($medium-down) {
      .site-header-wrapper--static {
        display: none;
      }
      .site-header-wrapper--sticky .site-header {
        background: $color-header-background;
      }
    }
    @include breakpoint($large-up) {
      .site-header-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        .site-header {
          background: $color-white;
        }
      }
    }
  }
}

.peeking-header {
  .site-content {
    @include breakpoint($large-up) {
      margin-top: -24px;
    }
  }
}

body.toolbar-drawer {
  padding-top: 0 !important;
}

.site-content,
body:not(.html) .site-header-wrapper--static + div:not(.site-content), //pg
body:not(.html) .site-header-wrapper--static + section:not(.site-content), //pg,
body:not(.html) .account-section,
body:not(.html) .password-request-page {
  padding-top: 0;
  @include breakpoint($medium-up) {
    padding-top: 0;
  }
  @include breakpoint($large-up) {
    .csspositionsticky & {
      padding-top: 0;
    }
  }
}

body {
  &.checkout-samples,
  &.checkout-index,
  &.checkout-viewcart {
    .site-content {
      padding-top: 50px;
      margin: 0 auto;
      @include breakpoint($medium-up) {
        padding-top: 75px;
      }
      @include breakpoint($large-up) {
        padding-top: 0;
      }
    }
  }
  &.checkout-samples,
  &.checkout-index {
    .site-header {
      &__mobile-checkout-back {
        display: block;
      }
      &__offer,
      &__menu,
      &__utilities {
        display: none;
        visibility: hidden;
      }
      &__navbar--mobile {
        .flex-1,
        .site-header__navbar-menu {
          display: none;
        }
        .site-header__navbar-navigation {
          display: block;
        }
      }
      &__logo {
        margin: 15px auto 0;
      }
    }
  }
}

.peeking-header:not(.sticky-nav-active) {
  &.front,
  &.page-products,
  &.node-type-page,
  &.page-node {
    @include breakpoint($medium-down) {
      .site-header-wrapper--static {
        display: none;
      }
      .site-header-wrapper--sticky .site-header {
        background: $color-header-background;
      }
    }
    @include breakpoint($large-up) {
      .site-header-wrapper {
        left: 0;
        z-index: 10;
        width: 100%;
        .site-header {
          background: transparent;
          @at-root .menu-overlay-active & {
            background: $color-white;
          }
        }
      }
    }
  }
}

.sticky-nav-active .site-header-wrapper {
  .gnav-util__icon {
    background: transparent;
  }
  .site-header__utilities {
    margin-top: 0;
  }
  .gnav-util__content--search {
    .gnav-util__content-close {
      color: $color-light-gray;
      font-size: 32px;
      top: 4px;
    }
    .gnav-util__content-inner {
      padding: 1em 0;
      .esearch-nav__form-fields {
        .esearch-nav__form-field {
          background: $color-pink-2;
        }
      }
    }
  }
  .gnav-util {
    @include breakpoint($large-up) {
      position: relative;
    }
  }
  .gnav-util--search .gnav-util__content--search {
    @include breakpoint($large-up) {
      top: 53px; // magic number for visual alignment
    }
  }
  @include breakpoint($large-up) {
    .site-header {
      background: $color-pink-2;
    }
    .site-header__trustmark img {
      max-height: 46px;
    }
  }
  @include breakpoint(width $large-up $xlarge-up) {
    .site-header {
      .site-header__logo {
        padding-left: 10px;
      }
    }
  }
}

.site-header-wrapper {
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 200;
  transition: all 0.4s ease-in;
  button {
    background: transparent;
    border: none;
  }
  .site-header__mobile-checkout-back {
    display: none;
  }
  @include breakpoint($large-up) {
    .site-header__right-menu {
      position: relative;
      width: max-content;
    }
    .site-header__logo {
      order: 1;
    }
    .site-header {
      background: $color-header-background;
      padding-bottom: 0;
    }
    .site-header__menu {
      display: none;
      &--sticky {
        display: block;
        @include breakpoint($large-up) {
          display: flex;
        }
      }
    }
    .site-header .gnav-section__dropdown {
      top: $sticky-height; // magic number to align dropdowns to sticky
    }
    .site-header__navbar--desktop {
      display: flex;
      width: 93%;
      max-width: $max-width;
      height: 56px;
      padding-top: 8px;
      @include breakpoint($large-up) {
        padding-top: 0;
      }
    }
    .site-header__utilities {
      @include breakpoint($large-up) {
        display: flex;
        align-items: center;
      }
    }
    .site-header .gnav-section {
      @include breakpoint(width $large-up $xlarge-up) {
        margin: 0 10px;
      }
    }
    .site-header__menu--sticky {
      order: 2;
      flex: 1 0 auto;
      position: static;
    }
    .site-header__menu--sticky.active-nav {
      .gnav-util--search {
        .gnav-util__content--search.hidden {
          display: none;
        }
      }
    }
    .site-header__menu-wrapper {
      position: static;
    }
    .site-header__menu-list {
      padding-top: 0;
      .gnav-section__link-wrapper {
        font-size: 14px;
      }
    }
    .site-header__utilities .flex-grid {
      padding-right: 10px;
    }
    .gnav-util--search {
      position: relative;
      width: auto;
      .gnav-util__content--search.hidden {
        display: block;
        visibility: visible !important;
        position: static;
      }
      .gnav-util__content-inner {
        padding: 0;
        position: relative;
        max-width: $max-width;
        margin: auto;
      }
      input[type='text'] {
        width: 150px;
        border-color: transparent;
        height: 45px;
        @include breakpoint($xlarge-up) {
          width: 200px;
          height: $sticky-height;
        }
      }
    }
    .gnav-util--account,
    .gnav-util--cart {
      flex: 0 0 32px;
      @include breakpoint($large-up) {
        flex: 0 0 auto;
      }
    }
  }
  .csspositionsticky & {
    position: -webkit-sticky;
    position: sticky;
    z-index: 200;
    top: -$mobile-signup-block-height; // magic number for visual alignment
    transition: top 0.3s;
    @include breakpoint($medium-up) {
      top: -$signup-block-height; // magic number for visual alignment
    }
  }
  @include breakpoint($large-up) {
    .csspositionsticky & {
      .gnav-util {
        position: relative;
      }
    }
    .sticky-nav-active & {
      top: -53px; // magic number for visual alignment
    }
    .site-header {
      background: $color-white;
    }
    .site-header__trustmark {
      height: 100%;
      img {
        display: block;
        height: 100%;
        transition: all 0.3s;
      }
    }
    .gnav-util__content--search {
      .gnav-util__content-close {
        color: $color-light-gray;
        font-size: 32px;
      }
      .gnav-util__content-inner {
        padding: 1rem;
      }
      .esearch-nav {
        margin-top: 24px;
      }
      input[type='text'] {
        @include font--proxima-nova;
        @include input-placeholder {
          @include font--proxima-nova;
          font-weight: 200;
          font-size: 30px;
          text-align: center;
          color: $color-light-gray;
        }
        padding: 0;
        display: block;
        text-align: center;
        font-weight: 200;
        font-size: 30px;
        height: auto;
        border: none;
        border-bottom: 2px solid $color-gold;
        -webkit-appearance: none;
        line-height: 38px;
        background: $color-pink-2;
        width: 425px;
        margin: 0 auto;
      }
    }
  }
  .site-header__utilities {
    a.selectBox .selectBox-arrow::after {
      top: 23%;
    }
  }
}

.site-header {
  transition: background 1s;
  background: $color-white;
  @include breakpoint($large-up) {
    background: $color-white;
    position: relative;
    z-index: 100;
    padding: 0 0 30px 0;
  }
  .sticky-nav-active & {
    background: $color-pink-2;
  }
  .content-dim--content {
    @include breakpoint($medium-down) {
      z-index: 1;
    }
  }
  .nav-hidden {
    display: none;
  }
  a {
    text-decoration: none;
  }
  .site-header__wrapper {
    position: relative;
    z-index: 2;
    @include breakpoint($large-up) {
      z-index: 120;
      background: $color-white;
      .sticky-nav-active & {
        background: $color-header-background;
      }
    }
  }
  .site-header__offer {
    background: $color-pink-1;
    font-size: 12px;
    padding: 10px;
    text-align: center;
    height: fit-content;
    @include breakpoint($large-up) {
      font-size: 16px;
      text-align: left;
      padding: 10px 50px;
    }
    .content-block-sitewide-banner--enabled & {
      position: relative;
    }
    p {
      margin-bottom: 0;
    }
  }
  .gnav-section__link--top {
    letter-spacing: 1px;
  }
  .gnav-section:not(.gnav-section--mobile-expanded) .gnav-section__dropdown {
    @include breakpoint($medium-down) {
      padding-left: 10px;
      margin-bottom: 15px;
    }
    @include breakpoint($large-up) {
      text-align: left;
      left: 0;
      top: 57px;
      width: 100%;
      margin-top: -1px;
      &--top {
        padding: 20px 10%;
        position: absolute;
        background: $color-overlay;
        &:after {
          @include borderGradient;
          content: ' ';
          bottom: 10px;
        }
      }
      &-wrapper {
        padding-top: 8px;
        margin: 0 auto 20px;
        max-width: $max-width-large;
      }
      .nav-hidden {
        display: block;
      }
      .block .node > .content {
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        -ms-flex-flow: row wrap;
        flex-wrap: wrap;
      }
      .gnav-section__dropdown-wrapper--spaced {
        .block .node > .content {
          display: -ms-grid;
          display: grid;
          -webkit-box-pack: normal;
          -ms-flex-pack: normal;
          justify-content: normal;
          -ms-grid-columns: 20% 20% 20% 20% 20% 20%;
          grid-template-columns: auto auto auto auto auto;
          .menu__item--category {
            @for $rows from 1 to 3 {
              @for $cols from 1 to 6 {
                &:nth-child(#{($rows - 1) * 5 + $cols}) {
                  -ms-grid-row: $rows;
                  -ms-grid-column: $cols;
                }
              }
            }
          }
        }
      }
    }
  }
  &__content {
    background: $white;
    display: none;
    padding: 10px;
    position: absolute;
    width: 100%;
    @include breakpoint($large-up) {
      margin: auto;
      padding: 30px 20px;
    }
  }
  &__main {
    @include breakpoint($large-up) {
      position: relative;
    }
  }
  &__navbar {
    margin: auto;
    &.flex-grid {
      width: 93%;
      @include breakpoint($medium-down) {
        padding: 10px 0 8px;
      }
    }
    .gnav-util__icon {
      align-items: center;
      background: transparent;
      display: flex;
      fill: $color-black;
      justify-content: center;
      @include breakpoint($large-up) {
        width: 42px;
        height: 42px;
      }
      &.gnav-util__icon--search {
        background: transparent;
      }
      svg,
      img {
        width: 22px;
        fill: $color-black;
        @include breakpoint($medium-up) {
          width: 32px;
        }
      }
      svg.icon--hamburger {
        width: 18px;
        @include breakpoint($medium-up) {
          width: 25px;
        }
      }
    }
  }
  &__navbar--mobile {
    .flex-1 {
      &:nth-child(2) {
        margin-left: 10px;
        margin-right: auto;
      }
      &:nth-child(4) {
        margin-right: 10px;
        margin-left: auto;
      }
    }
  }
  &__utilities {
    position: absolute;
    right: 0;
    top: 15px;
    z-index: 200;
    @include breakpoint($large-up) {
      position: static;
      order: 3;
    }
    .flex-grid {
      justify-content: flex-end;
    }
    .gnav-util {
      display: inline-block;
      transition: background 1s;
      margin-left: 10px;
      text-align: center;
      align-self: center;
      .gnav-util__content {
        display: none;
        &.gnav-util__content--cart {
          display: block;
        }
      }
      &.active .gnav-util__content {
        display: block;
        opacity: 0;
      }
    }
  }
  &__right-menu {
    @include breakpoint($large-up) {
      width: auto;
      order: 2;
    }
  }
  @include breakpoint($large-up) {
    .gnav-section {
      display: inline-block;
      margin: 0 20px;
    }
    .gnav-section__dropdown-wrapper .gnav-section__link {
      color: $color-gold;
      display: inline-block;
      margin-bottom: 6px;
      letter-spacing: 1px;
      text-transform: uppercase;
      &:hover {
        color: $color-link-hover;
      }
    }
  }
  .menu__item--category {
    @include breakpoint($large-up) {
      margin-bottom: 60px;
    }
  }
  .menu__item--category--image {
    display: none;
    @include breakpoint($medium-up) {
      display: block;
      text-align: center;
      .menu__image-title {
        display: block;
      }
      a {
        display: block;
      }
    }
    @include breakpoint($large-up) {
      padding: 0 10px;
      max-width: 270px;
      margin-bottom: 0;
      span {
        display: block;
      }
    }
  }
  .menu__item--category--image + .menu__item--category--image {
    @include breakpoint($large-up) {
      margin-left: 30px;
    }
  }
  .gnav-section__dropdown-wrapper:not(.gnav-section__dropdown-wrapper--spaced) {
    .menu__item--category--links + .menu__item--category--links,
    .menu__item--category--image + .menu__item--category--links,
    .menu__item--category--links + .menu__item--category--image {
      @include breakpoint($large-up) {
        margin: 0 30px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .menu__image-media {
    margin-bottom: 15px;
    @include breakpoint($medium-up) {
      display: none;
    }
    @include breakpoint($large-up) {
      display: block;
    }
  }
  .menu__image-title {
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 1px;
    margin-bottom: 3px;
    text-transform: uppercase;
    @include breakpoint($medium-up) {
      text-align: left;
    }
    @include breakpoint($large-up) {
      text-align: center;
    }
  }
  .menu__image-subtitle {
    font-weight: normal;
    font-size: 11px;
    letter-spacing: 1px;
  }
  &__logo {
    max-width: 50%;
    text-align: center;
    @include breakpoint($large-up) {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 340px;
      position: relative;
      z-index: 100;
    }
    .site-header__logo-link {
      img {
        aspect-ratio: 45 / 11;
        max-height: 55px;
        @include breakpoint($large-up) {
          max-height: 44px;
        }
      }
    }
  }
  // Menu Styling
  &__menu {
    &--sticky {
      display: none;
    }
    @include breakpoint($medium-down) {
      @include transition(transform 0.4s);
      @include transform(translateX(-100%));
      position: fixed;
      z-index: 100;
      top: 0;
      width: 70%;
      height: 100vh;
      color: $color-black;
      display: flex;
      flex-direction: column;
    }
    @include breakpoint($large-up) {
      position: relative;
      min-height: 0;
      padding-bottom: 0;
      z-index: 100;
    }
    &.active {
      @include transform(translateX(0));
    }
    &.menu-position {
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }
    a {
      @include breakpoint($large-up) {
        font-weight: bold;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
  &__menu-background {
    @include breakpoint($medium-down) {
      background: $color-overlay;
    }
  }
  &__menu-triggers {
    text-align: right;
    padding: 15px 10px;
    a {
      display: inline-block;
    }
    svg,
    img {
      width: 22px;
      display: inline-block;
      margin-left: 6px;
      @include breakpoint($medium-up) {
        width: 32px;
      }
    }
  }
  &__menu-wrapper {
    padding-bottom: 30px;
    -webkit-overflow-scrolling: touch;
    position: relative;
    height: auto;
    @include breakpoint($large-up) {
      margin: auto;
      padding-bottom: 0;
      overflow: visible;
    }
  }
  &__menu-wrapper--links {
    padding-top: 30px;
    @include breakpoint($medium-down) {
      flex: 1;
      background: $color-gnav-background;
    }
  }
  // List and link stylings
  &__menu-list {
    margin: auto;
    @include breakpoint($medium-down) {
      width: 80%;
    }
    @include breakpoint($large-up) {
      padding-top: 15px;
      text-align: center;
    }
    .gnav-link--subcategory-link {
      a {
        display: block;
        font-weight: normal;
        line-height: 1.7;
        @include breakpoint($medium-down) {
          padding-left: 5px;
        }
      }
      &--highlight {
        a {
          color: $color-gold;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 14px;
          letter-spacing: 1px;
        }
      }
    }
    .gnav-section__link-wrapper {
      position: relative;
      &.gnav-section__link-wrapper-logout {
        display: none;
      }
      .elc-user-state-logged-in & {
        &.gnav-section__link-wrapper-login {
          display: none;
        }
        &.gnav-section__link-wrapper-logout {
          display: block;
        }
      }
      .gnav-section__border {
        @include heartBorder;
        display: block;
        opacity: 0;
        transition: all 0.3s;
      }
      .gnav-section__link--top:hover + .gnav-section__border {
        opacity: 1;
      }
      .active + .gnav-section__border {
        opacity: 1;
      }
      @include breakpoint($large-up) {
        .gnav-section__link--top {
          &:hover,
          &.active {
            color: $color-gold;
          }
        }
        .gnav-section__border {
          display: none;
        }
      }
      &--less-padding {
        .gnav-section__link {
          @include breakpoint($large-up) {
            margin-bottom: 5px;
          }
        }
      }
    }
    .menu__image-title {
      @include breakpoint($medium-up) {
        font-weight: bold;
        margin-bottom: 20px;
        display: block;
        letter-spacing: 2px;
      }
    }
    .gnav-section__link,
    .gnav-section__shop-all {
      font-weight: bold;
      margin-bottom: 20px;
      display: inline-block;
      letter-spacing: 2px;
      @include breakpoint($large-up) {
        margin-bottom: 0;
      }
      & + .icon {
        @include transform(rotate(-90deg));
        cursor: pointer;
        display: inline-block;
        height: 7px;
        margin: -5px 0 0 5px;
        transition: all 0.3s;
        width: 7px;
      }
      &.active + .icon {
        transform: rotate(0);
      }
      &.gnav-section__link--no-dropdown + .icon {
        display: none;
      }
      & + .icon {
        @include breakpoint($large-up) {
          display: none;
        }
      }
      &--top {
        &:hover {
          color: $color-black;
        }
        @include breakpoint($large-up) {
          &.active {
            border-bottom-color: $color-gold;
          }
        }
      }
    }
  }
  .gnav-section__shop-all {
    display: none;
    @include breakpoint($medium-up) {
      font-weight: bold;
      margin-bottom: 20px;
      display: block;
      letter-spacing: 2px;
      a {
        text-transform: capitalize;
      }
      .right-caret {
        display: none;
      }
    }
    @include breakpoint($large-up) {
      font-size: 12px;
      text-align: right;
      .right-caret {
        display: inline-block;
      }
      a {
        font-weight: lighter;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }
  }
  .gnav-section__shop-all-title {
    display: none;
    @include breakpoint($large-up) {
      display: inline-block;
      font-weight: bold;
    }
  }
  .gnav-util--account {
    form .header-medium {
      @include breakpoint($medium-up) {
        font-size: 28px;
      }
    }
  }
  .site-header__country,
  a.selectBox.selectBox--alt .selectBox-label {
    font-size: 11px;
    letter-spacing: 1px;
    color: $color-black;
    text-transform: uppercase;
    transform: translateY(-1px);
  }
  .site-header__country {
    display: inline-block;
    transform: translateY(-2px);
  }
  // SelectBox duality. Style <select> to match selectBox
  .site-header__language {
    @include ltr-rule(padding-right, 10px);
    text-transform: uppercase;
    font-weight: 400;
    color: $color-black;
  }
  .mantle-media-asset {
    img,
    video {
      display: none;
      @include breakpoint($large-up) {
        display: block;
        height: auto;
        width: auto;
        max-width: 100%;
      }
    }
  }
}

// Loads in offers section
.site-header__banner {
  background-color: $color-pink-1;
  height: 36px;
  line-height: 39px;
  overflow: hidden;
  position: absolute;
  right: 50px;
  top: 0;
  width: auto;
  z-index: 2;
  img {
    max-height: 39px;
    width: auto;
  }
}

// Expand a nav item by default
.gnav-section--mobile-expanded {
  @include breakpoint($medium-down) {
    .menu__item--category--links {
      margin-bottom: 20px;
    }
    .gnav-section__shop-all {
      display: none;
      @include breakpoint($large-up) {
        display: block;
      }
    }
    > .gnav-section__link-wrapper {
      display: none;
    }
    .gnav-section__link-wrapper {
      text-transform: uppercase;
    }
    .gnav-section__dropdown--top {
      display: block;
    }
  }
}
