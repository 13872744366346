.sidebar-page {
  @include breakpoint($medium-up) {
    border-bottom: $black solid 1px;
  }
}

.sidebar-page__sidebar {
  padding: 1em;
  border-top: $black solid 1px;
  @include breakpoint($medium-up) {
    float: left;
    width: 28%;
    margin: 0 2% 0 0;
    border-right: $black solid 1px;
    border-top-width: 0;
  }
}

.sidebar-page__content {
  padding: 1em;
  @include breakpoint($medium-up) {
    float: right;
    margin: 0;
    padding: 1em 0;
    width: 70%;
  }
}
